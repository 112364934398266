.product-black-box {
    background-color: rgba(0, 0, 0, 0.881);
    box-sizing: border-box;
    width: 100%;
    height: 480px;
    display: flex;
    justify-content: center; 
    align-items: center; 
    flex-direction: column;
}

.products-title {
    display: flex;
    justify-content: center; 
    align-items: center;
    color: rgb(255, 255, 255);
    font-size: 3rem;
    font-family: "Teko", sans-serif;
    font-weight: 450;
    letter-spacing: 1.4px;
    background-color: rgb(193, 0, 0);
    box-sizing: border-box;
    width: 25%;
    height: 80px;
    text-align: center;
}

.product-summary-container {
    display: flex;
    justify-content: space-between;
    align-items: center; 
    width: 80%;
}

.product-summary-title {
    margin: 0;
    padding: 10px 0;
    font-family: "DM Serif Display", serif;
    font-size: 3rem;
    text-align: left;
    color: white;
}

.product-summary {
    margin: 0;
    font-family: "Montserrat", sans-serif;
    font-size: 1.1rem; 
    line-height: 1.6;
    text-align: left;
    color: white;
}

.product-page-image {
    height: 300px;
    width: 500px;
    margin-right: 10px;
}

.spacing {
    margin-left: 35px;
}

.image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Creates 3 columns */
    gap: 10px; /* Space between images */
    padding: 40px; /* Padding around the grid */
    margin-top: 25px;
}

.product-item {
    position: relative;
}

.grid-image {
    width: 100%;
    height: 250px; /* Maintain aspect ratio */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: adds a shadow for visual separation */
    border-radius: 5px;
}

.shingles {
    position: absolute; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the button */
    background-color: rgb(156, 0, 0);
    color: whitesmoke;
    font-size: 1.4rem;
    font-family: "Teko", sans-serif;
    font-weight: 450;
    letter-spacing: 1.4px;
    border: none;
    width: 170px;
    height: 50px;
    transition: background-color 0.5s ease, opacity 0.5s ease; 
}

.shingles:hover {
    background-color: red;
}

.roof-colors {
    font-family: "Montserrat", sans-serif;
    font-size: 1rem;
    line-height: 10px;
}

@media (max-width: 768px) {

    .product-black-box {
        height: auto;
    }

    .products-title {
        font-size: 2rem; 
        width: 50%;
        height: auto; 
        margin-top: 15px;
    }

    .product-summary-container {
        flex-direction: column; 
        align-items: center; 
        text-align: center; 
    }

    .product-page-image {
        width: 110%; 
        max-width: none; 
        height: auto;
        margin-left: 10px;
    }

    .spacing {
        margin-left: 0;
        padding: 20px 0; 
    }

    .product-summary-title {
        font-size: 2rem;
        padding: 10px 0;
    }

    .product-summary {
        font-size: 1rem;
        padding: 0; 
    }

    .image-grid {
        grid-template-columns: repeat(1, 1fr); /* Creates 3 columns */
        gap: 5px; 
        padding: 40px; 
        margin-top: 10px;
    }

    .shingles {
        font-size: 1.5rem;
        width: 170px;
        height: 50px;
        margin-top: 20px;
    }
}