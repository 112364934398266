/* Import Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Teko:wght@300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

/* General Styles */
.first-img {
    width: 100%;
    max-width: 100%; /* Ensure image doesn't exceed its container */
    height: auto; 
    margin: 0 auto; /* Center horizontally */
}

.center-text {
    text-align: center;
}

.image-container {
    position: relative;
    text-align: center;
}

.overlay-text {
    position: absolute; /* Use absolute positioning to place it relative to the closest positioned ancestor */
    top: 20%; /* Adjust this value to move the text up */
    left: 50%; /* Center the text horizontally */
    transform: translateX(-50%); /* Center the text horizontally */
    color: rgb(255, 255, 255);
    font-size: 2.2rem;
    font-family: "Teko", sans-serif;
    font-weight: 450;
    letter-spacing: 1.4px;
    padding: 10px;
    background-color: rgb(193, 0, 0);
    width: 70%;
    height: 70px;
}

.overlay-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the button */
    background-color: rgb(193, 0, 0);
    color: whitesmoke;
    font-size: 1.4rem;
    font-family: "Teko", sans-serif;
    font-weight: 450;
    letter-spacing: 1.4px;
    border: none;
    width: 170px;
    height: 50px;
    transition: background-color 0.5s ease, opacity 0.5s ease;
}

.overlay-button:hover {
    background-color: red;
}

.quality-roofing {
    font-family: "Teko", sans-serif;
    font-weight: 400;
    font-size: 2.5rem;
    padding: 10px 25px; /* Adjust padding for better spacing */
    letter-spacing: 1px;
    background-color: rgba(0, 0, 0, 0.881);
    color: whitesmoke;
    text-align: left; /* Center text */
}

.summary-container {
    width: 50%; 
    box-sizing: border-box;
    background-color: whitesmoke;
    padding: 20px; /* Add padding for better spacing */
}

.summary-title {
    margin: 0;
    padding: 10px; 
    font-family: "DM Serif Display", serif;
    font-size: 2.5rem;
    text-align: left;
}

.summary {
    margin: 0;
    padding: 20px; 
    font-family: "Montserrat", sans-serif;
    font-size: 1rem;
    line-height: 1.6;
    text-align: left;
}

.summary-roof {
    width: 50%; 
    max-width: 50%; /* Ensure it doesn't overflow */
    height: auto; 
}

.brand-box-title {
    background-color: rgba(0, 0, 0, 0.881);
    box-sizing: border-box;
    width: 100%;
    height: 100px;
    margin-top: 50px; 
    font-family: "DM Serif Display", serif;
    text-align: center;
    padding-top: 30px;
}

.brand-title {
    color: whitesmoke;
    font-size: 2.5rem;
    text-align: center;
    padding: 8px;
}

.brand-box {
    background-color: rgba(0, 0, 0, 0.881);
    box-sizing: border-box;
    width: 100%;
    height: auto; 
    padding: 20px; /* Add padding for spacing */
    text-align: center; /* Center content */
    display: flex;
    justify-content: space-around; /* Distribute items evenly */
    flex-wrap: wrap; /* Allow items to wrap to the next line */
}

.brands {
    width: 15%; 
    max-width: 300px; /* Ensure consistency */
    height: auto; 
    margin-bottom: 20px; /* Add margin for spacing */
    /*clip-path: inset(5% 20% 15% 10% round 5% 20% 0 10%);*/
    background-color: rgb(255, 255, 255);
    overflow: hidden; /* Ensure image doesn't overflow */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    border-radius: 5px;
    
}

.brands img {
    width: 100%;
    height: auto;
}

.side-by-side {
    display: flex;
    justify-content: space-between;
    padding: 20px 100px;
}
  
.column {
    width: 45%;
    text-align: center;
    font-family: "DM Serif Display", serif;
}
  
.title {
    font-family: "DM Serif Display", serif;
    font-size: 2rem; 
    margin-bottom: 10px; 
}
  
.description {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-size: 1rem;
}

.container {
    position: relative;
    width: 100%;
    height: 100%;
    padding-bottom: 400px;
    padding-top: 50px;
}

.quote-container {
    position: absolute;
    right: 30px;
    width: 50%;
    box-sizing: border-box;
    background-color: whitesmoke;
    text-align: center;
}

.quote-title {
    width: 100%; 
    height: 100px;
    margin-bottom: 50px;
    padding: 25px; 
    font-family: "DM Serif Display", serif;
    font-size: 2.5rem; 
    background-color: rgba(0, 0, 0, 0.881);
    color: rgb(255, 255, 255);
    box-sizing: border-box;
    padding-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

input {
    width: 400px;
    height: 45px;
    padding: 5px;
    margin-bottom: 15px;
    font-family: "Teko", sans-serif;
    font-weight: 450;
    letter-spacing: 1.4px;
    font-size: 1rem;
    border: solid 0.1px black;
    background-color: whitesmoke;
}

.submit-button {
    font-family: "Teko", sans-serif;
    font-weight: 450;
    letter-spacing: 1.4px;
    font-size: 1.2rem;
    color: whitesmoke;
    background-color: red;
    border: none;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: darkred;
}

.submit::after {
    content: "";
    display: table;
    clear: both;
}

.quote-contact-container {
    position: absolute;
    left: 25%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center; 
    justify-content: center; 
}

.quote-contact-title {
    padding: 10px;
}

.quote-contact {
    font-family: "Montserrat", sans-serif;
    font-size: 1rem; 
    color: rgb(0, 0, 0);
    text-align: left;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.quote-contact a {
    text-decoration: none;
    color: inherit;
}

.quote-contact a:hover,
.quote-contact a:focus,
.quote-contact a:active {
    text-decoration: underline;
    color: rgb(0, 0, 0);
}

.quote-contact-item {
    margin-bottom: 20px; 
}

.icons {
    margin-right: 10px;
    font-size: 2rem;
    width: 100px;
    height: 70px;
}


/* Media Queries for Responsive Design */

@media (max-width: 1200px) {

    .first-img {
        height: 200px; 
    }

    .summary-container {
        width: 100%; 
        padding: 10px; 
    }

    .summary-title {
        font-size: 2rem; 
        padding: 10px; 
        text-align: left;
    }

    .summary {
        font-size: 1rem; 
        padding: 10px; 
        text-align: left;
    }

    .brands {
        width: 20%;
        max-width: 120px; 
    }

    .side-by-side {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 20px; 
    }
    
    .column {
        width: 100%; 
        text-align: center;
        font-family: "DM Serif Display", serif;
    }
    
    .title {
        font-family: "DM Serif Display", serif;
        font-size: 2rem;
        margin-bottom: 10px;
    }
    
    .description {
        font-family: "Montserrat", sans-serif;
        font-size: 1rem;
        text-align: center;
    }

    .container {
        position: relative;
        width: 50%;
        padding-bottom: 0px; 
        padding-top: 0px; 
        margin-left: 40px;
    }
    
    .quote-container {
        position: relative;
        width: 350px;
        box-sizing: border-box;
        background-color: whitesmoke;
        text-align: center;
        padding: 20px; 
    }
    
    .quote-title {
        margin-bottom: 20px; 
        font-family: "DM Serif Display", serif;
        font-size: 1rem;
        background-color: rgba(0, 0, 0, 0.881);
        color: rgb(255, 255, 255);
    }
    
    input {
        width: 100%; 
        max-width: 400px; 
        height: 45px;
        padding: 5px;
        margin-bottom: 15px;
        font-family: "Teko", sans-serif;
        font-weight: 450;
        letter-spacing: 1.4px;
        font-size: 1rem;
        border: solid 0.1px black;
        background-color: whitesmoke;
    }
    
    .submit-button {
        width: 100%; 
        max-width: 170px; 
        height: 50px;
        font-family: "Teko", sans-serif;
        font-weight: 450;
        letter-spacing: 1.4px;
        font-size: 1.2rem;
        color: whitesmoke;
        background-color: red;
        border: none;
        transition: background-color 0.3s ease;
    }
    
    .submit-button:hover {
        background-color: darkred;
    }
    
    .quote-contact-container {
        position: relative;
        width: 100%;
        text-align: center;
        margin-left: 100px;
        margin-top: 150px;
    }

    .quote-contact {
        text-align: center;
    }
}

@media (max-width: 768px) {
    .overlay-text {
        font-size: 1.1rem; 
        width: 90%; 
        height: 40px; 
        top: 5%; 
        margin-top: 10px;
        padding-top: 10px;
    }

    .overlay-button {
        font-size: 1rem; 
        width: 110px; 
        height: 30px; 
        margin-top: 20px;
        padding-top: 3px;
    }

    .quality-roofing {
        font-size: 2rem; 
        padding: 10px; 
    }

}

/* Media query for mobile screens */
@media (max-width: 768px) {
    .summary-roof {
        width: 100%; 
        max-width: 100%; 
    }
}