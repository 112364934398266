.footer {
    background-color: rgba(0, 0, 0, 0.881);
    box-sizing: border-box;
    width: 100%;
    height: 350px;
    margin-top: 100px;
    padding-top: 20px;
}

.logo-footer {
    width: 600px;
    margin-top: 20px;
    margin-left: 20px;
    padding-bottom: 50px;
}

.contact-footer {
    font-family: "Teko", sans-serif;
    font-size: 1.3rem; 
    color: whitesmoke;
    text-align: left;
    float: right;
    padding-right: 75px;
    margin-top: 45px;
}

.icons-footer {
    margin-right: 10px;
    color: whitesmoke;
}

.socials {
    display: flex;
}

.insta {
    font-size: 1.8rem;
    color: whitesmoke;
    display: flex;
    margin-left: 750px;
    margin-top: 60px;
}

.rights {
    font-size: 0.8rem;
    color: whitesmoke;
    padding-left: 10px;
}

@media (max-width: 768px) {
    .footer {
        padding: 20px;
        text-align: center;
        height: 100%;
    }

    .logo-footer {
        margin: 0 auto; 
        padding-bottom: 20px;
        width: 360px;
    }

    .contact-footer {
        float: none;
        padding: 0 20px; 
        text-align: center; 
        margin-bottom: 20px; 
    }

    .socials {
        flex-direction: column; 
        align-items: center; 
    }

    .insta {
        margin-left: 0; 
        margin-bottom: 10px;
    }
}