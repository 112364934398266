.contact-us {
    font-family: "Teko", sans-serif;
    font-weight: 450;
    letter-spacing: 1.4px;
    font-size: 3rem;
    text-align: center;
}

.contact-title {
    position: absolute;
    top: 50%; /* Adjust to position vertically */
    left: 50%; /* Adjust to position horizontally */
    transform: translate(-50%, -50%); /* Center text */
    color: rgb(255, 255, 255);
    font-size: 2.3rem;
    font-family: "Teko", sans-serif;
    font-weight: 450;
    letter-spacing: 1.4px;
    background-color: rgb(193, 0, 0);
    box-sizing: border-box;
    width: 20%;
    height: 70px;
    align-items: flex-end;
    padding-top: 10px; 
    text-align: center;
}

.map-container-wrapper {
    width: 100%;
    height: 400px;
    justify-content: center;
    display: flex;
    margin: 0 auto;
    margin-top: 50px;
}
  
.map-container {
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
}

.get-directions {
    position: absolute; 
    background-color: rgb(193, 0, 0);
    color: whitesmoke;
    font-size: 1.4rem;
    font-family: "Teko", sans-serif;
    font-weight: 450;
    letter-spacing: 1.4px;
    border: none;
    width: 170px;
    height: 50px;
    transition: background-color 0.5s ease, opacity 0.5s ease; 
    left: 20px;
    margin-top: 70px;
}

.get-directions:hover {
    background-color: red;
}

@media (max-width: 768px) {
    .contact-title {
        width: 150px;
        height: 50px;
        font-size: 2rem;
        padding-top: 1px;
    }
}