@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.about-img {
    width: 100%;
    max-width: 100%; 
    height: 400px;
    display: block; 
    margin: 0 auto; 
}

.about-image-container {
    position: relative;
    text-align: center;
}

.about-title {
    position: absolute;
    top: 50%; /* Adjust to position vertically */
    left: 50%; /* Adjust to position horizontally */
    transform: translate(-50%, -50%); /* Center text */
    color: rgb(255, 255, 255);
    font-size: 2.2rem;
    font-family: "Teko", sans-serif;
    font-weight: 450;
    letter-spacing: 1.4px;
    background-color: rgb(193, 0, 0);
    box-sizing: border-box;
    width: 20%;
    height: 70px;
    align-items: flex-end;
    padding-top: 10px; 
    text-align: center;
}

.quality-roofing-2 {
    font-family: "Teko", sans-serif;
    font-weight: 400;
    font-size: 2.5rem;
    padding-left: 40px;
    height: 80px;
    letter-spacing: 1px;
    background-color: rgba(0, 0, 0, 0.881);
    color: whitesmoke;
    padding-top: 15px;
}

.about-summary-container {
    float: left; /* Float container to the right */
    width: 50%;
}

.about-summary-title {
    width: 100%; 
    margin: 0;
    padding: 10px 50px; 
    font-family: "DM Serif Display", serif;
    font-size: 3rem; 
    text-align: left; 
}

.about-summary {
    width: 100%; 
    margin: 0; 
    padding: 20px 50px; 
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-size: 1rem;
    line-height: 1.6; 
    text-align: left; 
}

.about-summary-roof {
    margin-top: 100px;
    height: 380px;
    width: 600px;
}

.about-box {
    background-color: rgb(241, 241, 241);
    box-sizing: border-box;
    width: 100%;
    height: 300px;
    display: block;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-size: 1rem;
    padding: 130px;
    padding-top: 80px;
    margin-bottom: 340px ;
}

b {
    font-size: 1.1rem;
}

.supplies-img {
    padding-top: 20px;
    height: 400px;
    width: 600px;
    justify-content: center;
    display: flexbox;
    box-sizing: border-box;
    border: 10px solid rgb(255, 255, 255);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.about-summary-container-2 {
    float: right; 
    width: 50%;
    margin-top: 20px;
    box-sizing: border-box;
    background-color: whitesmoke;
    height: 440px;
}

.about-summary-title-2 {
    width: 100%; 
    margin: 0;
    padding: 10px 50px; 
    font-family: "DM Serif Display", serif;
    font-size: 3rem; 
    text-align: left; 
}

.about-summary-2 {
    width: 100%; 
    padding: 20px 50px; 
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-size: 1rem;
    line-height: 1.6; 
    text-align: left; 
}

.about-summary-roof-2 {
    padding-top: 20px;
    height: 460px;
    width: 50%;
}

@media (max-width: 768px) {
    .about-title {
       width: 150px;
       height: 50px;
       font-size: 2rem;
       padding-top: 1px;
    }

    .about-img {
        width: 100%;
        max-width: 100%; 
        height: 200px;
        display: flex; 
    }

    .quality-roofing-2 {
        font-size: 1.8rem;
        padding-left: 20px;
        height: 70px;
    }

    .about-summary-container {
        width: 100%;
    }
    
    .about-summary-title {
        font-family: "DM Serif Display", serif;
        font-size: 2rem; 
        padding: 10px 40px; 
    }
    
    .about-summary {
        padding: 20px 40px; 
    }
    
    .about-summary-roof {
        display: flex;
    }
    
    .about-box {
        display: block;
        margin-top: 100px;
        padding: 20px;
        padding-top: 40px;
        margin-bottom: 200px;
    }
    
    b {
        font-size: 1.1rem;
    }
    
    .supplies-img {
        height: 250px;
        width: 350px;
    }
    
    .about-summary-container-2 {
        width: 100%;
        height: 700px;
    }
    
    .about-summary-title-2 {
        padding: 20px 40px; 
        font-size: 2rem; 
    }
    
    .about-summary-2 {
        width: 100%; 
        padding: 10px 40px; 
        
    }
    
    .about-summary-roof-2 {
        height: auto;
        width: 100%;
        padding-top: 0px;
    }
}