.catalog-black-box {
    background-color: rgba(0, 0, 0, 0.881);
    box-sizing: border-box;
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center; 
    align-items: center; 
    flex-direction: column;
}

.catalog-title {
    display: flex;
    justify-content: center; 
    align-items: center;
    color: rgb(255, 255, 255);
    font-size: 3rem;
    font-family: "Teko", sans-serif;
    font-weight: 450;
    letter-spacing: 1.4px;
    background-color: rgb(193, 0, 0);
    box-sizing: border-box;
    width: 25%;
    height: 80px;
    text-align: center;
}

.catalog-summary-container {
    display: flex;
    justify-content: space-between;
    align-items: center; 
    width: 80%;
}

@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap");

.product-container {
    display: flex; 
    justify-content: center; 
    align-items: center; 
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Creates 3 columns */
}

.product-text {
    text-align: center;
    width: 100%; 
    margin: 0;
    padding-top: 50px; 
    font-family: "Montserrat", sans-serif;
    font-size: 1.3rem;
    letter-spacing: 0.3px;
}

.product-image {
    width: 330px;
    height: 320px;
    padding-bottom: 20px;
}

@media (max-width: 1200px) {
    .catalog-black-box {
        width: 100%;
        height: 150px;
    }
    
    .catalog-title {
        width: 80%;
        height: 80px;
        margin-top: 15px;
    }
    
    .catalog-summary-container {
        display: flex;
        justify-content: space-between;
        align-items: center; 
        width: 80%;
    }
        
    .product-container {
        grid-template-columns: repeat(1, 1fr); /* Creates 3 columns */
    }
    
    .product-text {
        font-size: 1.5rem;
        padding-bottom: 30px;
    }
    
    .product-image {
        width: 300px;
        height: 300px;
        padding-bottom: 20px;
    }
}