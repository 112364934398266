/* header.css */

/*@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');*/
/*@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Oswald:wght@200..700&display=swap");*/
@import url("https://fonts.googleapis.com/css2?family=Teko:wght@300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&family=Teko:wght@300..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Slabo+27px&display=swap");

.custom-navbar {
  background-color: #000000 !important;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
}

.nav-link {
  margin-left: 50px;
  font-size: 1.4rem;
  color:ghostwhite;
  font-family: "Teko", sans-serif;
  font-optical-sizing: auto;
  font-weight: 450;
  letter-spacing: 1.5px;
  display: flex;
  align-items: center;
}

.nav-link:hover {
  color: red;
}

.nav-link.active {
  color: #ff0000 !important;
}

.navbar-nav {
  display: flex;
  justify-content: center; 
  align-items: center;
  width: 100%; 
}

.dropdown-toggle::after {
  display: none; /* Remove the default arrow from the dropdown toggle */
}

.dropdown-menu {
  background-color: #ffffff; 
}

.dropdown-item {
  color: rgb(0, 0, 0); 
}

.dropdown-item:hover {
  color: red;
}

.logo-container {
  text-align: center;
  padding: 10px;
  background-color: rgb(255, 255, 255);
}

.logo-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  height: 120px; 
  margin-right: 10px; 
}

.logo-text {
  font-size: 2.0rem;
  color:rgb(255, 0, 0);
  font-family: "Abril Fatface", serif;
  font-weight: 600;
  letter-spacing: 1.5px;
}

.logo-sub {
  font-size: 1rem;
  color:rgb(0, 39, 194);
  font-family: "Abril Fatface", serif;
  font-weight: 600;
  letter-spacing: 1.5px;
}

.call-us {
  background-color: rgb(193, 0, 0);
  box-sizing: border-box;
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Teko", sans-serif;
  font-optical-sizing: auto;
  color: whitesmoke;
  letter-spacing: 1px;
  font-size: 1.2rem;
  font-weight: bold;
}

.phone {
  font-size: 1rem;
  padding-right: 15px;
  margin-bottom: 5px;
}

.call-link {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
}

.call-link:hover {
  color: rgb(255, 255, 255);
}


@media (max-width: 1200px) {
  .logo {
    height: 80px; /* Adjust the height as needed */
  }
  
  .logo-text {
    font-size: 0.99rem;
    color:rgb(255, 0, 0);
    font-family: "Abril Fatface", serif;
    font-weight: 600;
    letter-spacing: 1.5px;
  }
  
  .logo-sub {
    font-size: 0.6rem;
    color:rgb(0, 39, 194);
    font-family: "Abril Fatface", serif;
    font-weight: 600;
    letter-spacing: 1.5px;
  }

  .custom-navbar {
    flex-direction: column;
    height: auto;
    align-items: flex-start; /* Align items to the start */
    padding: 10px 20px; /* Adjust padding */
  }

  .nav-link {
    margin-left: 0; /* Remove margin */
    margin-bottom: 10px; /* Add bottom margin for spacing */
    font-size: 1.2rem; /* Adjust font size */
  }

  .navbar-nav {
    flex-direction: column; /* Stack items vertically */
    width: auto; /* Adjust width */
    align-items: flex-start; /* Align items to the start */
  }

  .call-us {
    display: none;
  }
}